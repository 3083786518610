<template>
  <div>
    <ContentFilterPegawai
      v-if="!isUserRole && !hideFilter"
      ref="filterPegawai"
      :prefill="{
        date: currentDate
      }"
      @on-refresh="refreshPage"
    />
    <ContentNotFound :message="errorMsg" :loading="loading" v-if="!isAvailable">
      <template v-slot:action>
        <v-card-actions>
          <v-btn @click="emitPage" color="primary" class="px-10">
            <v-icon left>sync</v-icon>
            Muat Ulang
          </v-btn>
        </v-card-actions>
      </template>
    </ContentNotFound>
    <template v-else>
      <v-card flat class="pa-5">
        <v-row class="ml-3 mt-3">
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
            <v-card outlined class="rounded-lg pa-3">
              <v-row justify="center" class="mb-5">
                <v-avatar size="110">
                  <img :src="pegawaiSummary.photo" />
                </v-avatar>
              </v-row>
              <p class="font-weight-bold ma-0 text-center headline-color">
                {{ pegawaiSummary.NIP }}
              </p>
              <p
                class="ma-0 subtitle-2 font-weight-regular text-center headline-color"
              >
                {{ pegawaiSummary.name }}
              </p>
              <p
                class="ma-0 subtitle-2 font-weight-regular text-center headline-color"
              >
                {{ pegawaiSummary.unit_utama }}
              </p>
              <p
                class="ma-0 subtitle-2 font-weight-regular text-center headline-color"
              >
                {{ pegawaiSummary.unit_kerja_2 }}
              </p>
              <p
                class="ma-0 subtitle-2 font-weight-regular text-center headline-color"
              >
                {{ pegawaiSummary.unit_kerja_3 }}
              </p>
              <p
                class="ma-0 subtitle-2 font-weight-regular text-center headline-color"
              >
                {{ pegawaiSummary.unit_kerja_4 }}
              </p>
              <p
                class="ma-0 subtitle-2 font-weight-regular text-center headline-color"
              >
                Jabatan : {{ pegawaiSummary.kelas_jabatan }}
              </p>
              <!-- <v-btn
                small
                outlined
                color="#717171"
                class="px-5 mt-7 text-capitalize"
                >Panggilan Pelanggaran Disiplin</v-btn
              > -->
              <p
                @click="toggleHistory"
                class="mt-5 text-capitalize subtitle-2 primary-color hover-primary"
              >
                {{
                  showHistory
                    ? "Sembunyikan Riwayat Presensi"
                    : "Lihat Riwayat Presensi"
                }}
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
            <v-dialog
              ref="dialog"
              v-model="modal"
              persistent
              width="290px"
              v-if="!hideFilter"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="d-none d-sm-flex float-right mr-3 mt-2 subtitle-2 font-weight-regular headline-color"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2">today</v-icon>
                  {{ currentDate | dateMonthShortDisplay }}
                  {{ currentDate | dateYear }}
                  <v-icon small class="ml-2">settings</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="currentDateTemp"
                type="month"
                locale="id"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    currentDate = currentDateTemp;
                    modal = false;
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-tabs show-arrows height="50" v-model="tab" id="tab-data">
              <v-tab
                v-for="(item, i) in tabs"
                v-bind:key="i"
                class="mr-2 px-5 text-upppercase"
                >{{ item.text }}</v-tab
              >
            </v-tabs>
            <v-tabs-items>
              <transition name="slide-fade" mode="out-in">
                <keep-alive>
                  <component
                    :is="tabs[tab].component"
                    @on-change-date="handleChangeCurrentDate"
                    @on-check-in="handleCheckIn"
                    :currentDate="currentDate"
                  />
                </keep-alive>
              </transition>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row v-if="showHistory" class="px-5">
          <v-col cols="12" class="pt-10">
            <v-card-actions>
              <v-spacer />
              <div v-if="!isUserRole">
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="error"
                  @click="handleDeleteBulk"
                  >Hapus Catatan<v-icon class="ml-2" color="error"
                    >highlight_off</v-icon
                  ></v-btn
                >
              </div>

              <div v-if="isUserLogin && isWeekday">
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="handleCheckIn"
                  >Isi Presensi<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </div>

              <v-btn
                text
                class="text-capitalize caption headline-color"
                color="primary"
                @click="handleBulkCatatan"
                >Buat Catatan<v-icon class="ml-2" color="primary"
                  >add_circle</v-icon
                ></v-btn
              >
            </v-card-actions>
            <v-data-table
              id="datatable"
              :headers="headers"
              :items="historyAttendances"
              :items-per-page="-1"
              hide-default-header
              hide-default-footer
            >
              <template #header>
                <thead>
                  <tr id="border-btm">
                    <th rowspan="2" class="text-center">Tanggal</th>
                    <th rowspan="2" class="text-center">Jam Kerja</th>
                    <th rowspan="2" class="text-center">Datang</th>
                    <th rowspan="2" class="text-center">Pulang</th>
                    <th rowspan="2" class="text-center">Pulang Cepat</th>
                    <th rowspan="1" colspan="2" class="text-center">
                      Denda Kehadiran
                    </th>
                    <th rowspan="2" class="text-center">Kurang Jam</th>
                    <th rowspan="1" colspan="2" class="text-center">
                      Keberadaan
                    </th>
                    <th rowspan="2" class="text-center">Total Denda</th>
                    <th rowspan="2" class="text-center">Keterangan</th>
                    <th rowspan="2" class="text-center">Aksi</th>
                  </tr>
                  <tr>
                    <th class="text-center">Datang</th>
                    <th class="text-center">Pulang</th>
                    <th class="text-center">Durasi</th>
                    <th class="text-center">Denda</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr v-if="item.jam_kerja != 'N/A'">
                  <td class="text-center">{{ item.attendance_date | date }}</td>
                  <td class="text-center">
                    <span
                      class="hover-primary"
                      @click="() => handleClickLogAttendance(item)"
                      >{{ item.jam_kerja }}</span
                    >
                  </td>
                  <td class="text-center">{{ item.check_in_time }}</td>
                  <td class="text-center">{{ item.check_out_time }}</td>
                  <td class="text-center">{{ item.check_out_result }}</td>
                  <td class="text-center">{{ item.check_in_penalty }}</td>
                  <td class="text-center">{{ item.check_out_penalty }}</td>
                  <td class="text-center">{{ item.attendance_result }}</td>
                  <td class="text-center">{{ item.present_result }}</td>
                  <td class="text-center">{{ item.present_penalty }}</td>
                  <td class="text-center">{{ item.pinalty }}</td>
                  <td class="text-center">{{ item.schedule_type }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          :color="
                            item.btn_note == 'add' ? 'success' : 'primary'
                          "
                          @click="handleCheck(item)"
                          ><v-icon>offline_pin</v-icon></v-btn
                        >
                      </template>
                      <span>Keterangan Izin</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          :color="
                            item.btn_skip == 'add' ? 'success' : 'primary'
                          "
                          @click="handleAdd(item)"
                          ><v-icon>note_add</v-icon></v-btn
                        >
                      </template>
                      <span>Tidak Berada di Tempat Kerja</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <template v-if="nowAndYesterday(item)">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            small
                            :color="item.log_activity ? 'primary' : 'success'"
                            @click="handleNote(item, true)"
                            ><v-icon>assignment</v-icon></v-btn
                          >
                        </template>
                        <template v-else-if="beforeToday(item)">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            small
                            color="secondary"
                            @click="handleNote(item, false)"
                            ><v-icon>assignment</v-icon></v-btn
                          >
                        </template>
                      </template>
                      <span>Catatan Kegiatan</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr v-else>
                  <td colspan="13" class="text-center">
                    {{ item.attendance_date | date }} - {{ item.schedule_ket }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <CatatanBulkDialog
          :single="!isEdit"
          ref="catatanBulk"
          @on-refresh="getDetailSchedule"
        />
        <AttendanceUpdateDialog
          ref="attendanceUpdate"
          @on-refresh="getDetailSchedule"
        />
        <AbsenDialog ref="absen" @on-refresh="getDetailSchedule" />
        <AttendanceHistoryDialog ref="attendanceLog" />
        <AttendanceNotesDialog
          ref="attendanceNotes"
          @on-refresh="getDetailSchedule"
        />
        <CatatanDeleteBulkDialog
          single
          ref="catatanDeleteBulk"
          @on-refresh="getDetailSchedule"
        />
      </v-card>
    </template>
  </div>
</template>
<script>
import PegawaiService from "@/services/resources/pegawai.service";
import { mapEmployeeRootField, mapSummaryRootField } from "@/store/helpers";
import { mapGetters } from "vuex";

const ContentNotFound = () => import("@/components/Content/NotFound");
const ContentFilterPegawai = () => import("@/components/Content/FilterPegawai");
const CatatanBulkDialog = () => import("@/components/Dialog/CatatanBulk");
const AbsenDialog = () => import("@/components/Dialog/Absen");
const AttendanceHistoryDialog = () =>
  import("@/components/Dialog/AttendanceHistory");
const AttendanceUpdateDialog = () =>
  import("@/components/Dialog/AttendanceUpdate");
const AttendanceNotesDialog = () =>
  import("@/components/Dialog/AttendanceNotes");
const CatatanDeleteBulkDialog = () =>
  import("@/components/Dialog/CatatanDeleteBulk");
// Tabs
const JadwalPresensi = () => import("./Tab/JadwalPresensi");
const CapaianKerja = () => import("./Tab/CapaianKerja");

export default {
  components: {
    ContentFilterPegawai,
    JadwalPresensi,
    CapaianKerja,
    CatatanBulkDialog,
    AttendanceUpdateDialog,
    AbsenDialog,
    AttendanceHistoryDialog,
    AttendanceNotesDialog,
    CatatanDeleteBulkDialog,
    ContentNotFound
  },
  props: {
    id: { type: String, required: false, default: null },
    hideFilter: { type: Boolean, required: false, default: false },
    dateMonthProps: { type: String, required: false, default: null }
  },
  data() {
    return {
      dateNow: new Date().toISOString().substr(0, 10),
      currentDate: new Date().toISOString().substr(0, 7),
      currentDateTemp: new Date().toISOString().substr(0, 7),
      tab: 0,
      showHistory: true,
      isEdit: false,
      modal: false,
      loading: false,
      tabs: [
        {
          id: "jadwal-presensi",
          text: `Jadwal Presensi`,
          icon: "",
          component: "JadwalPresensi"
        },
        {
          id: "capaian-kerja",
          text: `Capaian Kerja`,
          icon: "",
          component: "CapaianKerja"
        }
      ],
      headers: [
        {
          text: "Tanggal",
          value: "attendance_date",
          sortable: false,
          align: "center"
        },
        {
          text: "Jam Kerja",
          value: "jam_kerja",
          sortable: false,
          align: "center"
        },
        {
          text: "Masuk",
          value: "check_in_time",
          sortable: false,
          align: "center"
        },
        {
          text: "Keluar",
          value: "check_out_time",
          sortable: false,
          align: "center"
        },
        {
          text: "Pulang Cepat",
          value: "check_out_result",
          sortable: false,
          align: "center"
        },
        {
          text: "Kehadiran Pagi",
          value: "check_in_penalty",
          sortable: false,
          align: "center"
        },
        {
          text: "Kehadiran Sore",
          value: "check_out_penalty",
          sortable: false,
          align: "center"
        },
        {
          text: "Kurang Jam",
          value: "attendance_result",
          sortable: false,
          align: "center"
        },
        {
          text: "Keberadaan Durasi",
          value: "present_result",
          sortable: false,
          align: "center"
        },
        {
          text: "Keberadaan Denda",
          value: "present_penalty",
          sortable: false,
          align: "center"
        },
        {
          text: "Total Denda",
          value: "pinalty",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ]
    };
  },
  computed: {
    isAvailable() {
      return this.pegawaiSummary.NIP != null;
    },
    ...mapEmployeeRootField({
      personal: "personal",
      employee: "employee",
      keluarga: "keluarga",
      unit_kerja: "unit_kerja",
      riwayat: "riwayat"
    }),
    ...mapSummaryRootField({
      pegawaiSummary: "pegawai",
      kinerjaSummary: "kinerja",
      kehadiranSummary: "kehadiran",
      historyAttendances: "historyAttendances"
    }),
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isUserLogin() {
      return this.currentUser?.NIP === this.id;
    },
    isUserRole() {
      return this.currentUser.role === "USER";
    },
    isWeekday() {
      return (
        this.historyAttendances &&
        this.historyAttendances.find(item =>
          this.$moment(item.attendance_date).isSame(this.dateNow)
        )?.status1 !== "100001"
      );
    }
  },
  watch: {
    currentDate() {
      this.getDetailSchedule();
    }
  },
  methods: {
    isNow(date) {
      return this.$moment(date).isSame(this.dateNow);
    },
    handleChangeCurrentDate(val) {
      this.currentDate = val;
    },
    handleBulkCatatan() {
      this.isEdit = false;
      this.$refs.catatanBulk.changeSingle([
        {
          NIP: this.pegawaiSummary.NIP,
          name: this.pegawaiSummary.name
        }
      ]);
    },
    handleClickLogAttendance(item) {
      this.$refs.attendanceLog.show({
        NIP: this.pegawaiSummary.NIP,
        nama: this.pegawaiSummary.name,
        date: item.attendance_date
      });
    },
    handleCheck(item) {
      this.isEdit = true;
      if (item.status2) {
        this.$refs.catatanBulk.editSingle({
          trans_id: item.status2,
          NIP: this.pegawaiSummary.NIP,
          name: this.pegawaiSummary.name,
          date: item.attendance_date
        });
      } else {
        this.$refs.catatanBulk.createSingle({
          trans_id: item.status2,
          NIP: this.pegawaiSummary.NIP,
          name: this.pegawaiSummary.name,
          date: item.attendance_date
        });
      }
    },
    handleAdd(item) {
      this.$refs.attendanceUpdate.edit({
        NIP: this.pegawaiSummary.NIP,
        nama: this.pegawaiSummary.name,
        skip_date: item.attendance_date,
        skip_durasi: null,
        skip_start: null,
        skip_end: null
      });
    },
    handleNote(item, active) {
      if (active) {
        this.$refs.attendanceNotes.insert({
          attendance_id: item.attendance_id,
          NIP: this.pegawaiSummary.NIP,
          nama: this.pegawaiSummary.name,
          attendance_date: item.attendance_date,
          notes: item.log_activity
        });
      } else {
        this.$refs.attendanceNotes.view({
          attendance_id: item.attendance_id,
          NIP: this.pegawaiSummary.NIP,
          nama: this.pegawaiSummary.name,
          attendance_date: item.attendance_date,
          notes: item.log_activity
        });
      }
    },
    handleCheckIn() {
      const selectedAttendance = this.historyAttendances.find(item =>
        this.$moment(item.attendance_date).isSame(this.dateNow)
      );
      this.$refs.absen.insert({
        NIP: this.currentUser.NIP,
        nama: this.currentUser.name,
        attendance_date: selectedAttendance?.attendance_date
      });
    },
    handleDeleteBulk() {
      const payload = {
        employees: [
          {
            NIP: this.pegawaiSummary.NIP,
            name: this.pegawaiSummary.name
          }
        ]
      };
      this.$refs.catatanDeleteBulk.createSingle(payload);
    },
    toggleHistory() {
      this.showHistory = !this.showHistory;
    },
    nowAndYesterday(item) {
      const yesterday = this.$moment(this.dateNow)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      return (
        this.$moment(item.attendance_date).isSame(this.dateNow) ||
        this.$moment(item.attendance_date).isSame(yesterday)
      );
    },
    beforeToday(item) {
      return this.$moment(item.attendance_date).isBefore(this.dateNow);
    },
    refreshPage(item) {
      if (item.employeeId) this.id = item.employeeId;
      this.emitPage();
    },
    emitPage() {
      if (this.$route.query && this.$route.query.dateNow) {
        this.dateNow = new Date(this.$route.query.dateNow)
          .toISOString()
          .substr(0, 10);
      }
      if (this.dateMonthProps) {
        this.currentDate = this.dateMonthProps;
        this.currentDateTemp = this.dateMonthProps;
      }
      if (this.$route.query && this.$route.query.dateMonth) {
        this.currentDate = this.$route.query.dateMonth;
        this.currentDateTemp = this.$route.query.dateMonth;
      }
      this.getDataProfile();
      this.getDetailSchedule();
    },
    // Service
    async getDataProfile() {
      try {
        this.loadingUnit = true;
        this.$store.commit("summary/resetPegawaiSummary");
        await PegawaiService.getDataProfile(this.id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.pegawaiSummary = this.$_.merge(this.pegawaiSummary, data);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailSchedule() {
      try {
        this.loading = true;
        this.errorMsg = "";
        this.$store.commit("employee/resetEmployeeState");
        await PegawaiService.getDetailSchedule(
          this.id,
          this.$options.filters.dateMonth(this.currentDate),
          this.$options.filters.dateYear(this.currentDate)
        )
          .then(response => {
            const { status, message, data } = response.data;
            if (status) {
              const { data_schedule, data_tukin } = data;
              const { kinerja, kehadiran } = data_tukin;
              this.historyAttendances = data_schedule;
              this.kinerjaSummary = this.$_.merge(this.kinerjaSummary, kinerja);
              this.kehadiranSummary = this.$_.merge(
                this.kehadiranSummary,
                kehadiran
              );
            } else {
              this.errorMsg = message;
            }
          })
          .catch(error => {
            this.errorMsg = "Data pegawai tidak ditemukan";
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.emitPage();
  }
};
</script>
<style lang="scss">
#tab-custom .v-tabs-bar {
  background-color: transparent;
}
#tab-custom .v-tab {
  background-color: #e0e0e0;
  color: #555555;
  border-radius: 15px 15px 0px 0px;
  &.v-tab--active {
    background-color: #1792e6;
    color: #ffffff;
  }
}
#tab-item-custom.v-tabs-items {
  border-top: 3px solid #1792e6;
}
</style>
